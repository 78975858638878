import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { country } from '@features/country/data';
import { round } from '@mongez/reinforcements';
import { TranslateModule } from '@ngx-translate/core';
import { CheckUserFeatureExistsUseCase } from 'app/core/usecases/user/check-user-feature-exists.usecase';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { ReturnPolicyLinkComponent } from 'app/presentation/shared/components/return-policy-link/return-policy-link.component';
import { DYNAMIC_PRICING_UF } from 'app/presentation/shared/constants';
import { CartModel } from 'src/app/core/domain/cart.model';
import { GetCountryVATByIsoCode3UseCase } from 'src/app/core/usecases/get-country-vat.usecase';
import { CurrencyShortNamePipe } from '../../shared/pipes/currency-short-name.pipe';
import { CurrencyTranslatePipe } from '../../shared/pipes/currency-translate.pipe';
import { MultitenancyService } from '../../shared/services/multitenancy.service';

@Component({
  selector: 'app-cart-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    AsyncPipe,
    TranslateModule,
    CurrencyShortNamePipe,
    CurrencyTranslatePipe,
    ReturnPolicyLinkComponent,
  ],
})
export class CartSummaryComponent extends LocalizedComponent implements OnInit, OnChanges {
  @Input() cartData: CartModel;

  @Input() isMarketClosed = false;

  @Input() isDuplicateAccount: boolean;

  @Input() shouldShowQuantityDiscount: boolean;

  @Output() submitOrderButtonClicked = new EventEmitter<void>();

  public currency: string;

  public shouldShowVAT = false;

  public canSubmitOrder = false;

  public dynamicPricingEnabled = false;

  constructor(
    private _multitenancyService: MultitenancyService,
    private _getCountryVATByIsoCode3UseCase: GetCountryVATByIsoCode3UseCase,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
  ) {
    super();
  }

  ngOnInit(): void {
    this.currency = this._multitenancyService.getCurrentCountry().currency.arabicName;

    this._checkIfShouldShowVat();
    this.setQuantityDiscount();
    this.checkIfDynamicPricingEnabled();
  }

  public setQuantityDiscount(): void {
    this.cartData.totalVat = +(this.cartData.totalVat * 1.5).toFixed(2).replace(/[.,]00$/, '');
    this.cartData.totalNetProfit = +(this.cartData.totalNetProfit * 1.5)
      .toFixed(2)
      .replace(/[.,]00$/, '');
    if (!this.cartData.totalQuantityDiscount || !this.shouldShowQuantityDiscount) {
      this.cartData.totalQuantityDiscount = 0;
    }
  }

  ngOnChanges(): void {
    this.canSubmitOrder = this.cartData.products.every(
      (p) => p.isAvailableToSeller && p.validity.isValid,
    );
  }

  public submitOrderClicked(): void {
    this.submitOrderButtonClicked.emit();
  }

  private _checkIfShouldShowVat() {
    this._getCountryVATByIsoCode3UseCase.execute(country.code).subscribe({
      next: (vatAmount) => {
        this.shouldShowVAT = vatAmount > 0;
      },
    });
  }

  public round(value?: number): number {
    return round(value || 0, 2);
  }

  private checkIfDynamicPricingEnabled(): void {
    this.dynamicPricingEnabled = this._checkUserFeatureExistsUseCase.execute(
      DYNAMIC_PRICING_UF(country.code),
    );
  }
}
